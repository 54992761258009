.App {
  text-align: center;
  /* font-family: "Mali"; */
}

.Mali {
  font-family: "Mali";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link { 
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  background: #c786d3;
  justify-content: start;
  align-items: center;
  text-align: center;
  height: auto;
  font-family: "Mali";
}

@media (max-width: 768px) {
  .navbar {
    /* display: flex;
    flex-direction: row; */
    height: auto;
    background: #61dafb;
    font-family: "Mali";
  }

  .navbar a {
    padding: 5 rem;
    text-decoration: none;
  }

  .nav {
    display: flex;
    flex-direction: column;
  }


} 

.btn {
  position: fixed;
  right: 0px;
  bottom: 0px;
  margin: 1rem;
}

.btn2 {
  position: fixed;
  right: 12px;
  bottom: 60px;
  margin: 1rem;
}

.btn3 {
  position: fixed;
  right: 12px;
  bottom: 110px;
  margin: 1rem;
}

.btn4 {
  position: fixed;
  right: 12px;
  bottom: 160px;
  margin: 1rem;
}